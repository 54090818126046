<template>
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="d-flex align-end mt-4 headText">
          <h2 class="headTextProject" :style="fontSize">Tacna</h2>
          <v-spacer></v-spacer>
          <v-btn small text @click="$router.go(-1)" class="orange--text" style="border: 2px solid orange">Back</v-btn>
        </div>
      </div>

      <div class="font-weight-light black--text caption mb-2">
        <p class="desc">(responsive design)</p>
      </div>

      <v-row>
        <v-col class="main-text">
          <br />
          <span class="textInsideProject"> Language: </span>
          JavaScript<br />
          <span class="textInsideProject"> Framework: </span>
          Vue + Router + Vuex (State management pattern + library)<br />
          <span class="textInsideProject">UI Library:</span> Vuetify
          <br />
          <span class="textInsideProject">Database:</span> SQL <br />
          <span class="textInsideProject">Server:</span>
          Firebase (Facebook and Google login API), www.Mars-server.net (data
          storage and manipulation using JS as backend language)<br />
          <span class="textInsideProject">devDependencies: </span>cli, babel,
          eslint, router, vuex, sass, vuetify...
          <br />
          <span class="textInsideProject">Short info: </span>Currently
          developing an SPA (Single-page application) for a humanitarian
          organization that raises funds through Facebook auctions. The app
          simplifies the process by automatically identifying the highest bid
          instead of manually comparing comments. Auctions end 24 hours after
          the first offer, and results can be easily shared on Facebook.

          <div class="d-flex justify-center git-gallery-live">
            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange"
              href="https://github.com/kirk7183/Tacna" target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> GitHub</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" @click="openGallery(0)">
              <i class="orange--text fas fa-bullseye pa-0"> Gallery</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange"
              href="http://www.tacna.rs" target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> Live demo </i>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Gallery -->
    <!-- <v-row class="ma-0">
      <v-col class="px-4">
        <h2 class="">Gallery</h2>
        <v-container class="mt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex xs6 sm4 lg2 v-for="(image, i) in images" :key="i" class="d-flex justify-center">
              <img v-lazy="image.src" :style="picSize" style="cursor: pointer" @click="openGallery(i)" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Import component LightBox za prikaz galerije sa kontrolama levo i desno-->
    <LightBox ref="lightbox" :media="images" :show-caption="true" :show-light-box="false" :closable="true" />
  </v-card>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

  export default {
    components: { LightBox },
    data() {
      return {
        images: [
          {
            thumb: require("@/assets/projects/Tacna/slika-1.png"),
            src: require("@/assets/projects/Tacna/slika-1.png"),
            caption: "<h4 class='pt-2'>Responsive look - mobile and tablet</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-2.png"),
            src: require("@/assets/projects/Tacna/slika-2.png"),
            caption: "<h4 class='pt-2'>Responsive look - mobile</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-3.png"),
            src: require("@/assets/projects/Tacna/slika-3.png"),
            caption: "<h4 class='pt-2'>Responsive look - tablet</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-4.png"),
            src: require("@/assets/projects/Tacna/slika-4.png"),
            caption: "<h4 class='pt-2'>Responsive look - desktop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-5.png"),
            src: require("@/assets/projects/Tacna/slika-5.png"),
            caption: "<h4 class='pt-2'>Responsive look - mobile</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-6.png"),
            src: require("@/assets/projects/Tacna/slika-6.png"),
            caption: "<h4 class='pt-2'>Responsive look - mobile</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-7.png"),
            src: require("@/assets/projects/Tacna/slika-7.png"),
            caption: "<h4 class='pt-2'>Responsive look - mobile</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-8.png"),
            src: require("@/assets/projects/Tacna/slika-8.png"),
            caption: "<h4 class='pt-2'>Responsive look - desktop</h4>",
          },
          // {
          //   thumb: require("@/assets/projects/Tacna/slika-9.png"),
          //   src: require("@/assets/projects/Tacna/slika-9.png"),
          //   caption: "<h4 class='pt-2'>Responsive look - desktop</h4>",
          // },
          {
            thumb: require("@/assets/projects/Tacna/slika-10.png"),
            src: require("@/assets/projects/Tacna/slika-10.png"),
            caption: "<h4 class='pt-2'>Auctions in progress - desktop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-11.png"),
            src: require("@/assets/projects/Tacna/slika-11.png"),
            caption: "<h4 class='pt-2'>Completed bids - desktop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-12.png"),
            src: require("@/assets/projects/Tacna/slika-12.png"),
            caption: "<h4 class='pt-2'>Responsive look - desktop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-13.png"),
            src: require("@/assets/projects/Tacna/slika-13.png"),
            caption: "<h4 class='pt-2'>Enter a new bid - desktop</h4>",
          },
          {
            thumb: require("@/assets/projects/Tacna/slika-14.png"),
            src: require("@/assets/projects/Tacna/slika-14.png"),
            caption: "<h4 class='pt-2'>Enter a new bid - mobile</h4>",
          },
        ],
      };
    },
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          // case "xs":
          // case "sm":
          //   return { "font-size": "21px !important" };
          default:
            return {
              "font-size": "1.3em",
              "letter-spacing": "0.05em",
              "text-transform": "uppercase",
            };
        }
      },
      picSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "lg":
            return { width: "95%", height: "80px" };
          default:
            //tj. xs, sm, md
            return { width: "auto", height: "96px", overflow: "hidden" };
        }
      },
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
      },
    },
  };
</script>

<style></style>
